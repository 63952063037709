// new Date() = 31 de março de 2023
export const dateInDayMonthAndYear = (date: string | Date) => {
  return new Date(date).toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
};

// new Date() = quinta-feira
export const dateOfWeek = (date: string | Date) => {
  return new Date(date).toLocaleDateString('pt-BR', {
    weekday: 'long',
  });
};

// new Date() = 18/10/2023
export const dateInDayMonthAndYearNumeric = (date: string | Date) => {
  return new Date(date).toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

// compara a data com a data atual
export const compareDates = (refundDate: string): boolean => {
  const currentDate = new Date();
  const refundDateParts = refundDate.split('/');
  const refundYear = parseInt(refundDateParts[2], 10);
  const refundMonth = parseInt(refundDateParts[1], 10) - 1;
  const refundDay = parseInt(refundDateParts[0], 10);

  const refundDateObj = new Date(refundYear, refundMonth, refundDay);

  return refundDateObj > currentDate;
};

// está dentro do limite de tempo?
export function adjustToUserTimezone(date: Date): Date {
  const userTimezoneOffset = new Date().getTimezoneOffset();
  return new Date(date.getTime() - userTimezoneOffset);
}

export function isWithinTimeThreshold(
  date: Date,
  thresholdInMilliseconds: number
): boolean {
  const now = new Date().getTime();
  return now - date.getTime() < thresholdInMilliseconds;
}

// 3 minutos em milisegundos
export const threeMinutesInMilliseconds = 3 * 60 * 1000;

export const formatDateAndHours = (date: Date) => {
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  const formattedData = `${day}/${month}/${year}`;

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  const formattedHours = `${hours}:${minutes}`;

  return {
    date: formattedData,
    hour: formattedHours,
  };
};
export const formatDateAndHoursUTC3 = (date: Date) => {
  const utcMinus3 = new Date(date.getTime() - 3 * 3600000);

  const day = String(utcMinus3.getUTCDate()).padStart(2, '0');
  const month = String(utcMinus3.getUTCMonth() + 1).padStart(2, '0');
  const year = utcMinus3.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const hours = String(utcMinus3.getUTCHours()).padStart(2, '0');
  const minutes = String(utcMinus3.getUTCMinutes()).padStart(2, '0');

  const formattedHours = `${hours}:${minutes}`;

  return {
    date: formattedDate,
    hour: formattedHours,
  };
};

export const countDaysBackFromToday = (days: number): string => {
  const today = new Date();
  today.setDate(today.getDate() - days);

  return today.toISOString();
};

export const calculateDaysBetween = (
  startDate: string | Date,
  endDate: string | Date
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  // Calcula a diferença em milissegundos e converte para dias
  return Math.round((end.getTime() - start.getTime()) / millisecondsPerDay);
};

export const calculateDateDiff = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDateFilter = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
